.About {
  //   display: flex;
  //   justify-content: flex-start;
  margin-left: 55px;
  width: 101%;

  &__container {
    max-width: 1000px;
    display: flex;

    justify-content: center;

    flex-direction: column;
    align-items: space-around;
    min-height: 100vh;
    margin: 0px auto;
    padding: 150px 0px;
  }
  h1 {
    display: flex;
    align-items: flex-end;
    width: 100%;
    font-size: 30px;
    margin: 10px 0px 40px;
    font-family: Calibre, "San Francisco", "SF Pro Text", -apple-system,
      system-ui, BlinkMacSystemFont, Roboto, "Helvetica Neue", "Segoe UI", Arial,
      sans-serif;
    color: #ccd6f6;
    font-weight: bold;

    &:before {
      counter-increment: section 1;
      content: "0" counter(section) ".";
      margin-right: 10px;
      font-family: "SF Mono", "Fira Code", "Fira Mono", "Roboto Mono",
        "Lucida Console", Monaco, monospace;
      font-weight: normal;
      color: #ff2e97;
      font-size: 20px;
      position: relative;
      bottom: 4px;
    }

    &:after {
      content: "";
      display: block;
      height: 1px;
      width: 185px;
      background-color: #2d3952;
      position: relative;
      top: -19px;
      margin-left: 20px;
    }
  }

  div {
    display: flex;
    justify-content: flex-start;
    font-family: Calibre, "San Francisco", "SF Pro Text", -apple-system,
      system-ui, BlinkMacSystemFont, Roboto, "Helvetica Neue", "Segoe UI", Arial,
      sans-serif;
    color: #ccd6f6;
    font-size: 14px;

    section {
      width: 387px;
    }

    a {
      color: #ff2e97;
    }

    ul {
      display: grid;
      grid-template-columns: repeat(3, minmax(140px, 200px));
      overflow: hidden;
      padding: 0;
      margin: 20px 0 0 0;
      list-style: none;

      li {
        position: relative;
        margin-right: 150px;
        margin-bottom: 10px;
        padding-left: 20px;
        font-family: "SF Mono", "Fira Code", "Fira Mono", "Roboto Mono",
          "Lucida Console", Monaco, monospace;
        font-size: 12px;
        color: #ccd6f6;
        &:before {
          content: "▹";
          position: absolute;
          left: 0;
          color: #ff2e97;
          font-size: 12px;
          line-height: 12px;
          margin-top: 3px;
        }
      }
    }
  }
  .container__image {
    margin-left: 125px;
    max-width: 350px;
    position: relative;

    &:before,
    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 96%;
      height: 100%;
      transition: all 0.25s ease-in-out;
    }
    &:before {
      top: 15px;
      left: 15px;
      // outline: 0px solid transparent;
      z-index: -1;
    }
    &:after {
      top: 25px;
      left: 70px;
      // background: #ff2e97;
      outline: 2px solid #ff2e97;

      z-index: -2;
    }
    &:hover {
      &:after {
        top: 15px;
        left: 60px;
        outline: 2px solid #ff2e97;
      }
    }
  }
}

// @media (max-width: 1200px) {
//   .container__image img {
//     display: none;
//   }
// }

//tablet
@media only screen and (device-width: 768px) {
  .About .container__image:before,
  .About .container__image:after {
    width: 105%;
    height: 89%;
    margin-left: 128px;
  }
  .me {
    margin-left: 120px;
  }
}

@media (min-device-width: 320px) and (max-device-width: 480px) {
  .About h1 {
    font-size: 28px;
  }
  .About .container__image {
    display: none;
  }

  .About__container {
    min-height: 120vh;
  }
}

@media (min-device-width: 1024px) and (max-device-width: 1025px) {
  .About .container__image:before,
  .About .container__image:after {
    margin-left: 200px;
  }
  .me {
    margin-left: 225px;
  }
}
