.Email {
  width: 40px;
  position: fixed;
  bottom: 0px;
  left: auto;
  right: 40px;
  z-index: 10;
  color: rgb(168, 178, 209);

  &__container {
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    &::after {
      content: "";
      display: block;
      width: 1px;
      height: 90px;
      background-color: rgb(168, 178, 209);
      margin: 0px auto;
    }
  }
  &__link {
    font-family: "SF Mono", "Fira Code", "Fira Mono", "Roboto Mono",
      "Lucida Console", Monaco, monospace;
    font-size: 12px;
    letter-spacing: 0.1em;
    -webkit-writing-mode: vertical-rl;
    writing-mode: vertical-rl;
    margin: 20px 5px;
    margin-left: 10px;
    padding: 0px;
    color: rgb(168, 178, 209);

    &:hover {
      color: #ff2e97;
    }
  }
}

// @media screen and (max-width: 792px) {
//   .Email {
//     display: none;
//   }
// }

@media screen and (min-width: 500px) and (max-width: 727px) {
  .Email {
    display: none;
  }
}

@media (min-device-width: 320px) and (max-device-width: 480px) {
  .Email {
    display: none;
  }
}
