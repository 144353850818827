.Projects {
  margin-left: 55px;
  width: 101%;
  &__container {
    max-width: 1000px;
    display: flex;

    justify-content: center;

    flex-direction: column;
    align-items: space-around;
    min-height: 100vh;
    margin: 0px auto;
    padding: 150px 0px;
  }
  h1 {
    display: flex;
    align-items: flex-end;
    width: 100%;
    font-size: 30px;
    margin: 10px 0px 40px;
    font-family: Calibre, "San Francisco", "SF Pro Text", -apple-system,
      system-ui, BlinkMacSystemFont, Roboto, "Helvetica Neue", "Segoe UI", Arial,
      sans-serif;
    color: #ccd6f6;
    font-weight: bold;

    &:before {
      counter-increment: section 3;
      content: "0" counter(section) ".";
      margin-right: 10px;
      font-family: "SF Mono", "Fira Code", "Fira Mono", "Roboto Mono",
        "Lucida Console", Monaco, monospace;
      font-weight: normal;
      color: #ff2e97;
      font-size: 20px;
      position: relative;
      bottom: 4px;
    }

    &:after {
      content: "";
      display: block;
      height: 1px;
      width: 185px;
      background-color: #2d3952;
      position: relative;
      top: -19px;
      margin-left: 20px;
    }
  }

  &__list {
    margin-left: -69px;
  }
  a {
    display: inline-blesock;
    color: inherit;
    text-decoration: none;
    text-decoration-skip-ink: auto;
  }

  button {
    border: 0;
    outline: 0;
    cursor: pointer;
  }

  ul,
  ol {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  svg {
    width: 100%;
    height: 100%;
    fill: currentColor;
  }

  img {
    width: 100%;
    max-width: 100%;
    vertical-align: middle;
  }

  @mixin outline {
    outline: 1px solid red;
  }

  @mixin flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @mixin flex-between {
    display: flex;
    justify-content: space-between;
  }

  @mixin tablet {
    @media (max-width: 600px) {
      @content;
    }
  }

  .project-container {
    max-width: 1000px;
    margin: 50px auto;
    font-family: Calibre, "San Francisco", "SF Pro Text", -apple-system,
      system-ui, BlinkMacSystemFont, Roboto, "Helvetica Neue", "Segoe UI", Arial,
      sans-serif;
  }

  .project {
    margin: 30px;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(6, 1fr);
    align-items: center;
    font-family: Calibre, "San Francisco", "SF Pro Text", -apple-system,
      system-ui, BlinkMacSystemFont, Roboto, "Helvetica Neue", "Segoe UI", Arial,
      sans-serif;

    @media screen and (max-width: 768px) {
      align-items: flex-start;
      width: 67%;
      margin-left: 70px;
    }
  }

  .project-content {
    font-family: Calibre, "San Francisco", "SF Pro Text", -apple-system,
      system-ui, BlinkMacSystemFont, Roboto, "Helvetica Neue", "Segoe UI", Arial,
      sans-serif;
    position: relative;
    grid-column: 1 / 7;
    grid-row: 1 / -1;

    @include tablet {
      grid-column: 1 / -1;
      padding: 40px;
    }

    .project-label {
      color: #ff2e97;
      font-family: monospace;
    }
    .project-title {
      font-family: Calibre, "San Francisco", "SF Pro Text", -apple-system,
        system-ui, BlinkMacSystemFont, Roboto, "Helvetica Neue", "Segoe UI",
        Arial, sans-serif;
      font-size: 2rem;
      margin: 10px 0 30px;
      color: #ccd6f6;
      font-weight: bold;

      &:hover {
        color: #ff2e97;
      }
    }
    .project-details {
      font-family: Calibre, "San Francisco", "SF Pro Text", -apple-system,
        system-ui, BlinkMacSystemFont, Roboto, "Helvetica Neue", "Segoe UI",
        Arial, sans-serif;
      font-size: 15px;
      line-height: 1.5;
      color: #8892b0;
      //   font-weight: bold;

      p {
        background-color: #8892b0;
        padding: 20px 25px;
        color: #ccd6f6;
        @include tablet {
          background-color: transparent;
          padding: 20px 0;
        }
      }

      ul {
        display: flex;
        margin-top: 20px;

        li {
          margin-right: 20px;
          color: rgb(168, 178, 209);
          font-family: monospace;
        }
      }
      .details {
        text-align: left;
      }
      .other__title {
        margin-left: -100px;
      }
    }
  }

  .project-img {
    position: relative;
    z-index: -1;
    // background-color: #64ffda;
    // background: linear-gradient(0.4turn, #ff2e97, #ff2e97);
    border-radius: 2px;
    grid-column: 6 / -1;
    grid-row: 1 / -1;

    @media screen and (max-width: 768px) {
      height: 100%;
    }
    @include tablet {
      grid-column: 1 / -1;
      opacity: 0.25;
    }

    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 3;
      background-color: #0a192f;
      mix-blend-mode: screen;
      border-radius: 2px;
    }

    img {
      border-radius: 2px;
      position: relative;
      mix-blend-mode: multiply;
      //   filter: grayscale(100%) contrast(1);

      @media screen and (max-width: 768px) {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.Others {
  margin-left: 340px;
  .other__projects {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    position: relative;
    gap: 15px;
    color: #ccd6f6;

    .one__project {
      box-shadow: rgba(2, 12, 27, 0.7) 0px 10px 30px -15px;
      display: flex;
      -webkit-box-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      flex-direction: column;
      align-items: flex-start;
      position: relative;
      height: 100%;
      background-color: #8892b0;
      padding: 2rem 1.75rem;
      border-radius: 3px;
      transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    }
    .other__icons {
      display: flex;

      justify-content: flex-start;

      margin-bottom: 30px;

      .folder__icon {
        color: rgb(100, 255, 218);
      }

      .link__icon {
        margin-right: -10px;
        color: rgb(168, 178, 209);

        a {
          position: relative;
          top: -10px;
          padding: 10px;
        }
      }
    }
    h5 {
      // display: block;
      // font-size: 0.83em;
      // margin-block-start: 1.67em;
      // margin-block-end: 1.67em;
      // margin-inline-start: 0px;
      // margin-inline-end: 0px;
      margin-left: -71px;
      font-size: 22px;
      color: rgb(204, 214, 246);
      margin: 0px 0px 10px;
      font-weight: bold;
      font-family: Calibre, "San Francisco", "SF Pro Text", -apple-system,
        system-ui, BlinkMacSystemFont, Roboto, "Helvetica Neue", "Segoe UI",
        Arial, sans-serif;
      .other__description {
        margin: 0px 0px 10px;
        font-size: 17px;
        color: #ccd6f6;
        font-family: Calibre, "San Francisco", "SF Pro Text", -apple-system,
          system-ui, BlinkMacSystemFont, Roboto, "Helvetica Neue", "Segoe UI",
          Arial, sans-serif;

        p {
          margin: 0px 0px 15px;
        }
      }
    }
  }
}

@media screen and (min-width: 500px) and (max-width: 727px) {
  .Others {
    margin-left: 51px;
    width: 400px;
  }
  .details {
    text-align: left;
  }

  .other__title {
    margin-left: -110px;
  }
}

@media (min-device-width: 320px) and (max-device-width: 480px) {
  .Projects .project-container {
    width: 490px;
  }

  .details {
    text-align: left;
  }

  .Projects .project {
    width: 93%;
  }

  .Projects__container {
    min-height: 120vh;
  }

  .other__title {
    font-size: 2rem;
    color: rgb(204, 214, 246);
    font-weight: bold;
    margin-left: 70px;
  }

  .Others {
    width: 350px;
    margin-left: 110px;
  }

  .details {
    text-align: left;
  }
}

@media only screen and (device-width: 768px) {
  .Projects .project {
    width: 99%;
  }
  .Projects .project-container {
    width: 1000px;
  }
  .details {
    text-align: left;
  }
  .other__title {
    margin-left: 224px;
  }
}

@media (min-device-width: 1024px) and (max-device-width: 1025px) {
  .Projects .project {
    width: 1140px;
  }

  .details {
    text-align: left;
  }

  .other__title {
    margin-left: 255px;
  }
}
