.Education {
  //   display: flex;
  //   flex-direction: flex-start;
  margin-left: 55px;
  width: 101%;

  &__container {
    // display: flex;
    // justify-content: flex-start;
    // font-family: "SF Mono", "Fira Code", "Fira Mono", "Roboto Mono",
    //   "Lucida Console", Monaco, monospace;
    // color: #ccd6f6;
    // font-size: 14px;

    max-width: 1000px;
    display: flex;

    justify-content: center;

    flex-direction: column;
    align-items: space-around;
    min-height: 100vh;
    margin: 0px auto;
    padding: 150px 0px;
  }

  &__selected {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: "-837px";
  }

  h2 {
    color: #ff2e97;
    font-weight: bold;
    display: flex;
    // margin-left: 45px;
    font-size: 25px;
  }

  h3 {
    display: flex;
    // margin-left: 45px;
    color: #ccd6f6;
    font-family: "SF Mono", "Fira Code", "Fira Mono", "Roboto Mono",
      "Lucida Console", Monaco, monospace;
  }

  h4 {
    display: flex;
    // margin-left: 45px;
    color: #ccd6f6;
    font-family: Calibre, "San Francisco", "SF Pro Text", -apple-system,
      system-ui, BlinkMacSystemFont, Roboto, "Helvetica Neue", "Segoe UI", Arial,
      sans-serif;
    font-size: 12px;
  }
}

h1 {
  display: flex;
  align-items: baseline;
  width: 100%;
  font-size: 30px;
  margin: 10px 0px 40px;
  font-family: Calibre, "San Francisco", "SF Pro Text", -apple-system, system-ui,
    BlinkMacSystemFont, Roboto, "Helvetica Neue", "Segoe UI", Arial, sans-serif;
  color: #ccd6f6;
  font-weight: bold;

  &:before {
    counter-increment: section 2;
    content: "0" counter(section) ".";
    margin-right: 10px;
    font-family: "SF Mono", "Fira Code", "Fira Mono", "Roboto Mono",
      "Lucida Console", Monaco, monospace;
    font-weight: normal;
    color: #ff2e97;
    font-size: 20px;
    position: relative;
    bottom: 4px;
  }

  &:after {
    content: "";
    display: block;
    height: 1px;
    width: 185px;
    background-color: rgb(45, 57, 82);
    position: relative;
    top: -5px;
    margin-left: 20px;
  }
}

.scrollbar,
i.fas {
  align-items: center;
}

.scrollbar {
  display: flex;
  flex-direction: column;
  top: 8%;
  // right: 49%;
  right: -35%;
  margin-top: -345px;

  position: relative;
  height: 60vh;
  justify-content: space-evenly;
  z-index: 10;
}

.scrollbar_line {
  position: absolute;
  top: 0;
  left: 48.5%;
  border-right: 2px solid rgb(168, 178, 209);
  height: 60vh;
  z-index: -1;

  transition: all 1s linear;
}

// .active_school {
//   background-color: #ff2e97;
//   //   // border-radius: 50%;
//   //   // width: 50px;

//   //   // display: inline-block;
//   //   // color: #000;

//   //   width: 50px;
//   //   height: 50px;
//   //   border-radius: 50%;
//   //   // box-shadow: 0 0 6px #fff;
//   //   padding: 0.5em 1.6em;
// }

.circle {
  color: #000;
  margin-left: -20px;
}

i.fas {
  display: inline-block;
  color: #000;
  display: flex;
  justify-content: center;
  text-align: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: 0 0 6px #fff;
  background-color: rgb(168, 178, 209);

  padding: 0.5em 0.6em;

  transition: all 1s linear;
  cursor: pointer;
}

@media (min-device-width: 320px) and (max-device-width: 413px) {
  .scrollbar,
  .scrollbar_line {
    height: 55vh;
  }

  .scrollbar {
    margin-left: 270px;
  }

  .circle {
    margin-left: -10px;
  }
  .Education__container {
    min-height: 120vh;
  }
}

@media screen and (min-device-width: 414px) and (max-device-width: 480px) {
  .scrollbar {
    margin-left: 320px;
  }
  .circle {
    margin-left: -10px;
  }
  .scrollbar,
  .scrollbar_line {
    height: 50vh;
  }
}

@media screen and (min-width: 500px) and (max-width: 727px) {
  .scrollbar {
    right: -28%;
  }

  .circle {
    margin-left: -5px;
  }
}

@media only screen and (device-width: 768px) {
  .scrollbar,
  .scrollbar_line {
    height: 35vh;
  }
  .scrollbar {
    margin-left: 647px;
  }
  .circle {
    margin-left: 10px;
  }
}

@media (min-device-width: 1024px) and (max-device-width: 1025px) {
  .scrollbar,
  .scrollbar_line {
    height: 30vh;
  }

  .circle {
    margin-left: -1px;
  }
  .scrollbar {
    margin-left: 600px;
  }
}
