.SocialMedia {
  width: 40px;
  position: fixed;
  bottom: 0;
  left: "40px";
  z-index: 10;
  color: rgb(168, 178, 209);

  &__list {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    margin: 0;
    list-style: none;
    margin-left: 55px;

    &:after {
      content: "";
      display: block;
      width: 1px;
      height: 90px;
      margin: 0 auto;
      background-color: rgb(168, 178, 209);
    }

    li:last-of-type {
      margin-bottom: 20px;
    }
    a {
      padding: 10px;
    }
    &__link {
      &:hover,
      &:focus {
        transform: translateY(-3px);
      }
      .icons {
        width: 18px;
        height: 18px;
      }
    }
  }
}

@media screen and (min-width: 500px) and (max-width: 727px) {
  .SocialMedia {
    display: none;
  }
}

@media (min-device-width: 320px) and (max-device-width: 480px) {
  .SocialMedia {
    display: none;
  }
}
