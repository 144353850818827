@import url("https://fonts.googleapis.com/css?family=Fira+Mono&display=swap");

.Nav {
  font-family: "SF Mono", "Fira Code", "Fira Mono", "Roboto Mono",
    "Lucida Console", Monaco, monospace;
  color: rgb(204, 214, 246);
}

.navbar,
.navbar-menu {
  background-color: transparent;
}

.navbar-item {
  color: rgb(204, 214, 246);
  font-size: 13px;
}

.navbar-link.is-active,
.navbar-link:focus,
.navbar-link:focus-within,
.navbar-link:hover,
a.navbar-item.is-active,
a.navbar-item:focus,
a.navbar-item:focus-within,
a.navbar-item:hover {
  color: #ff2e97;
  background-color: transparent;
}

.navbar-burger {
  color: rgb(204, 214, 246);
}

.navbar-burger:hover {
  color: #ff2e97;
}

.navbar-end {
  margin-right: 55px;
}

.button {
  background-color: transparent;
  color: #ff2e97;
  border-color: #ff2e97;
  margin-top: 18px;
  font-size: 13px;
  font-family: "SF Mono", "Fira Code", "Fira Mono", "Roboto Mono",
    "Lucida Console", Monaco, monospace;
}

.button:hover {
  filter: drop-shadow(5px 5px 10px white);

  color: #ff2e97;
  border-color: #ff2e97;
  margin-top: 11px;
  font-size: 13px;
  font-family: "SF Mono", "Fira Code", "Fira Mono", "Roboto Mono",
    "Lucida Console", Monaco, monospace;
}

i.fas {
  background-color: transparent;
  box-shadow: none;
  margin-left: 10px;
}
@media (min-device-width: 320px) and (max-device-width: 413px) {
  .navbar-burger {
    margin-left: 390px;
    margin-top: 8px;
  }
  .navbar-menu.is-active {
    margin-left: 200px;
  }
  .navbar-brand {
    margin-left: -10px;
  }
  .navbar-end {
    margin-right: -50px;
  }
}

@media screen and (min-device-width: 414px) and (max-device-width: 480px) {
  .navbar-burger {
    margin-top: 8px;
    margin-left: 450px;
  }
  .navbar-end {
    margin-right: -50px;
  }
  .navbar-menu.is-active {
    margin-left: 200px;
  }
}

@media screen and (min-width: 500px) {
  .navbar-burger {
    margin-left: 340px;
  }

  .navbar-menu.is-active {
    margin-left: 50px;
  }
  i.fas {
    margin-left: -10px;
    background-color: transparent;
    box-shadow: none;
  }
}

@media only screen and (device-width: 768px) {
  .navbar-burger {
    margin-left: 950px;
  }
  .navbar-menu.is-active {
    margin-left: 500px;
    display: flex;
  }
}

@media screen and (min-device-width: 1024px) and (max-device-width: 1025px) {
  .navbar-menu {
    margin-left: 790px;
  }
}
