// .Hero {
//   &__container {
//     max-width: 1000px;
//     display: flex;

//     justify-content: center;

//     flex-direction: column;
//     align-items: space-around;
//     min-height: 100vh;
//     margin: 0px auto;
//     // padding: 150px 0px;

//     .greetings {
//       color: #ff2e97;
//       font-size: 16px;
//       font-family: "SF Mono", "Fira Code", "Fira Mono", "Roboto Mono",
//         "Lucida Console", Monaco, monospace;
//       font-weight: normal;
//       margin: 0px 0px 20px 3px;
//       animation: type 4s steps(60, end);
//     }

//     h2 {
//       font-family: Calibre, "San Francisco", "SF Pro Text", -apple-system,
//         system-ui, BlinkMacSystemFont, Roboto, "Helvetica Neue", "Segoe UI",
//         Arial, sans-serif;
//       color: rgb(204, 214, 246);
//       font-size: 70px;
//       line-height: 1.1;
//       margin: 0px;
//       font-weight: bold;
//     }

//     h3 {
//       font-family: Calibre, "San Francisco", "SF Pro Text", -apple-system,
//         system-ui, BlinkMacSystemFont, Roboto, "Helvetica Neue", "Segoe UI",
//         Arial, sans-serif;
//       font-size: 60px;
//       line-height: 1.1;
//       color: rgb(136, 146, 176);
//       font-weight: bold;
//     }
//     div {
//       font-family: "SF Mono", "Fira Code", "Fira Mono", "Roboto Mono",
//         "Lucida Console", Monaco, monospace;

//       margin-top: 20px;
//       width: 100%;
//       font-size: 14px;
//       color: rgb(168, 178, 209);
//     }
//   }
//   span {
//     color: #ff2e97;
//   }
// }

.Hero {
  margin-left: 55px;
  width: 101%;
  text-align: left;

  &__container {
    max-width: 1000px;
    display: flex;

    justify-content: center;

    flex-direction: column;
    align-items: space-around;
    min-height: 100vh;
    margin: 0px auto;
    padding: 150px 0px;
  }

  .greetings {
    color: #ff2e97;
    font-size: 16px;
    font-family: "SF Mono", "Fira Code", "Fira Mono", "Roboto Mono",
      "Lucida Console", Monaco, monospace;
    font-weight: normal;
    margin: 0px 0px 20px 3px;
    animation: type 4s steps(60, end);
  }

  h2 {
    font-family: Calibre, "San Francisco", "SF Pro Text", -apple-system,
      system-ui, BlinkMacSystemFont, Roboto, "Helvetica Neue", "Segoe UI", Arial,
      sans-serif;
    color: rgb(204, 214, 246);
    font-size: 80px;
    line-height: 1.1;
    margin: 0px;
    font-weight: bold;
  }

  h3 {
    font-family: Calibre, "San Francisco", "SF Pro Text", -apple-system,
      system-ui, BlinkMacSystemFont, Roboto, "Helvetica Neue", "Segoe UI", Arial,
      sans-serif;
    font-size: 80px;
    line-height: 1.1;
    color: rgb(136, 146, 176);
    font-weight: bold;
  }
  div {
    font-family: Calibre, "San Francisco", "SF Pro Text", -apple-system,
      system-ui, BlinkMacSystemFont, Roboto, "Helvetica Neue", "Segoe UI", Arial,
      sans-serif;

    margin-top: 20px;
    width: 100%;
    font-size: 15px;
    color: rgb(168, 178, 209);
  }
}

//device
@media (min-device-width: 320px) and (max-device-width: 480px) {
  .Hero__container {
    min-height: 120vh;
  }
  .Hero h2 {
    font-size: 4em;
  }
  .Hero h3 {
    font-size: 2em;
  }

  .Hero p {
    font-size: 13px;
  }
}

//desktop min
@media screen and (min-width: 500px) and (max-width: 729px) {
  .Hero h2 {
    font-size: 5.1em;
  }
  .Hero h3 {
    font-size: 2.8em;
  }

  .Hero p {
    font-size: 14spx;
  }
}

// //tablet
// @media (min-device-width: 768px) and (max-device-width: 1023px) {
//   .Hero {
//     position: relative;
//     left: 13%;
//   }
//   .Hero__container {
//     min-height: 70vh;
//   }
// }

// @media screen and (min-device-width: 1024px) and (max-device-width: 1026px) {
//   .Hero {
//     position: relative;
//     left: 20%;
//   }

//   .Hero__container {
//     min-height: 81vh;
//   }
// }

//desktop
// @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
//   .Hero h3 {
//     font-size: 3em;
//     margin-top: 16px;
//   }
// }
